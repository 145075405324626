import React from 'react';
import ReactMarkdown from 'react-markdown';
import WikiLayout from 'src/components/WikiLayout'
import { generateArticle, generateTags, findArticle, generateForwardBack } from 'src/util/util.js'
import { BrowserRouter, Link, Routes, Route, Switch, useParams } from "react-router-dom";
import {wordNav} from './words_constants.js'
import Subpage from './Subpage'

const allWords = require('src/content/words/index.json')

function WordsSubpage(props) {
    const params = useParams();
    const pageUrl = params.page;
    const articleData = findArticle(allWords, pageUrl);

    return (<Subpage
        pageUrl={pageUrl}
        pageType="words"
        articleData={articleData}
        allData={allWords}
        nav={wordNav}
    />);
}

export default WordsSubpage;
