import React from 'react';
import ReactMarkdown from 'react-markdown';
import { BrowserRouter, Link, Routes, Route, Switch, useParams } from "react-router-dom";

import ContentIndexPage from './ContentIndexPage'
const allWords = require('src/content/words/index.json');

function WordsContentIndexPage(props) {
    let content = allWords.filter(w => w.visibility);
    let wordsHeader = (
        <header className="tag-header">
        <h1>Index</h1>
        <hr/>
        </header>
    );

    return <ContentIndexPage allData={content} pageType="words" header={wordsHeader} />;
}

export default WordsContentIndexPage;

