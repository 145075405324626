import React from 'react';
import ReactMarkdown from 'react-markdown';
import { BrowserRouter, Link, Routes, Route, Switch, useParams } from "react-router-dom";

import TagsPage from './TagsPage'
const allWords = require('src/content/words/index.json');

function WordsTagFilteredPage(props) {
    let tags = allWords.flatMap((x, i) => {return x.tags});

    let wordsHeader = (
        <header className="tag-header">
        <h1>Tags</h1>
        <hr/>
        </header>
    );

    return <TagsPage tags={tags} pageType="words" header={wordsHeader} />;
}

export default WordsTagFilteredPage;

