import React from 'react';
import { BrowserRouter, Link, Routes, Route, Switch } from "react-router-dom";

function Breadcrumb(props) {
    return (
        <ul className="breadcrumb">
        {props.stack.map((x, i) => {
            if (x.url != null) {
                return (
                    <li key={i}><Link to={x.url}>{x.name}</Link></li>
                );
            } else {
                return (
                    <li key={i}><p>{x.name}</p></li>
                );
            }

        })}
        </ul>
    );
}

export default Breadcrumb;
