import React from 'react';
import Breadcrumb from './Breadcrumb.js'
import Footer from './Footer.js'
import SideNavigation from './SideNavigation.js'

function WikiLayout(props) {
    return (
        <React.Fragment>
            <Breadcrumb stack={props.stack} />
            <section className="content central flex-wrapper">
                <SideNavigation links={props.nav} />
                <section className="content side">
                    {props.content}
                </section>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default WikiLayout;