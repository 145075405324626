import React from 'react';
import ReactMarkdown from 'react-markdown';
import WikiLayout from 'src/components/WikiLayout'
import { generateArticle, generateTags, findArticle, generateForwardBack } from 'src/util/util.js'
import { BrowserRouter, Link, Routes, Route, Switch, useParams } from "react-router-dom";

function Subpage(props) {
    if (!props.articleData) {
        window.location.href = "/404";
    }

    // script injection would require some page to match the script, but sanitize anyway.
    const alphaNumeric = /[^A-Za-z0-9-]/g
    let pageUrl = props.pageUrl;
    if (pageUrl != undefined) {
        pageUrl = pageUrl.replace(alphaNumeric, "");
    }

    let content = (
        <React.Fragment>
            <article>
                {props.articleData && generateArticle(props.articleData)}
            </article>
            {generateForwardBack(props.allData, pageUrl)}
        </React.Fragment>
    );



    let stack = [{"url": "/", "name": "home"}];
    if (props.pageType != undefined) {
        stack.push({"url": "/" + props.pageType, "name": props.pageType});
        stack.push({"url": null, "name": pageUrl});
    }
    if (props.stack != undefined) {
        stack = props.stack;
    }

    return (
    <React.Fragment>
        <WikiLayout
            stack={stack}
            nav={props.nav}
            content={content}
        />
    </React.Fragment>
    );
}

export default Subpage;
