import ContentFrontPage from './ContentFrontPage.js';
import TopNavigation from 'src/components/TopNavigation';

const allImages = require('src/content/images/index.json');

function ImagesContentFrontPage() {
    let content = allImages.filter(w => w.visibility);
    return <ContentFrontPage name="images" content={content}/>;
}

export default ImagesContentFrontPage;
