import React from 'react';
import ReactMarkdown from 'react-markdown';
import CentralLayout from 'src/components/CentralLayout'
import { BrowserRouter, Link, Routes, Route, Switch, useParams } from "react-router-dom";

function ContentIndexPage(props) {
    let content = (
        <React.Fragment>
            <section className="index-section">
            <ul>
            {props.allData.map((t, i) => {
                return (
                    <li><Link to={`/${props.pageType}/${t.url}`}>
                        {t.title}
                    </Link></li>
                );
            })}
            </ul>
            </section>
        </React.Fragment>
    );

    let stack = [{"url": "/", "name": "home"}];
    if (props.pageType != undefined) {
        stack.push({"url": "/" + props.pageType, "name": props.pageType});
        stack.push({"url": null, "name": "index"});
    }
    if (props.stack != undefined) {
        stack = props.stack;
    }

    return (<CentralLayout
        stack={stack}
        content={content}
        header={props.header}
    />);
}

export default ContentIndexPage;

