import { BrowserRouter, Link, Routes, Route, Switch, useParams } from "react-router-dom";

function TopNavigation(props) {
  return (
    <nav className="top-nav">
      <ul>
        {props.links.map((x, i) => {
            return <li><Link to={x.url}>{x.name}</Link></li>;
        })}
      </ul>
    </nav>
  );
}

export default TopNavigation;
