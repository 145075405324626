//import DefaultLayout from "./components/DefaultLayout";
import React from 'react';

function InvalidPage() {
  return (
    <React.Fragment>
        <h1>Not Found</h1>
        <p>The requested URL was not found on this server.</p>
    </React.Fragment>
  );
}

export default InvalidPage;
