import React from 'react';
import ReactMarkdown from 'react-markdown';
import ContentFrontPage from './ContentFrontPage'
import { BrowserRouter, Link, Routes, Route, Switch, useParams } from "react-router-dom";

import TagFilteredPage from './TagFilteredPage'
const allWords = require('src/content/words/index.json');

function WordsTagFilteredPage(props) {
    const params = useParams();
    const pageUrl = params.tag;
    let content = allWords.filter(w => w.tags.includes(params.tag));

    if (!content) {
        // Let TagFilteredPage handle no content
    }

    return <TagFilteredPage stack={props.stack} content={content} tag={params.tag} pageType="words"/>;
}

export default WordsTagFilteredPage;

