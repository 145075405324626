import React from 'react';
import ReactMarkdown from 'react-markdown';
import CentralLayout from 'src/components/CentralLayout'
import { generateArticle } from 'src/util/util.js'
import { BrowserRouter, Link, Routes, Route, Switch } from "react-router-dom";

function ContentFrontPage(props) {
    let content = (
        <React.Fragment>
            {props.content.map((x, i) => {
                return (
                    <article key={`article-${i}`}>
                        {generateArticle(x, props.name)}
                        {i != props.content.length - 1 && x["paraphernalia"] && <hr />}
                    </article>
                );
            })}
        </React.Fragment>
    );
    let stack = [{"url": "/", "name": "home"}, {"url": null, "name": props.name}]
    if (props.stack != undefined) {
        stack = props.stack;
    }

    // No results etc.
    if (props.contentOverride != undefined) {
        content = props.contentOverride;
    }

    return (
    <React.Fragment>
        <CentralLayout
            stack={stack}
            content={content}
            header={props.header}
        />
    </React.Fragment>
    );
}

export default ContentFrontPage;
