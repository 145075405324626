import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import CodeBlock from "src/components/CodeBlock.js";
import { BrowserRouter, Link, Routes, Route, Switch } from "react-router-dom";

function generateTags(tags) {
    if (tags.length == 0) {
        return "";
    }
    return (
        <p>Tags: {[tags.map((y, j) => {
            return (
                <span key={`tag-${j}`}>
                { !!j && ", "}
                <Link to={`/words/tag/${y}`}>{y}</Link>
                </span>
            );
            })]
        } </p>
    );
}

function generateArticle(x, name="words") {
    return (
        <React.Fragment>
            <hgroup>
                <h2>{x.title}</h2>
                { x.publishTimeUnixSecs > 0 && <p>{new Date(x.publishTimeUnixSecs * 1000).toDateString()}</p> }
            </hgroup>
            <div className={name}>
                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} renderers={{code: CodeBlock}}>{x.content}</ReactMarkdown>
            </div>
            { x["paraphernalia"] && (
                        <div className="paraphernalia">
                            <Link to={`/${name}/${x.url}`}>permalink</Link>
                            {generateTags(x.tags)}
                        </div>
            ) }
        </React.Fragment>
    );
}

/*
Yes, I know this has edge cases where it doesn't work.
It's my responsibility to ensure it remains working because this site is static.
*/
function findArticle(data, url) {
    let found = data.filter(x => x.url == url);
    if (found.length > 0) {
        return found[0];
    }
    return false;
}

function findArticleIndex(data, url) {
    // -1 or first index.
    return data.findIndex(x => x.url == url);
}

// direction is 1 or -1 depending on which way to search.
function findFirstInDirection(data, url, direction) {
    let index = findArticleIndex(data, url);
    if (index == -1) {
        return false;
    }
    index += direction;
    while (index >= 0 && index <= data.length - 1) {
        if (data[index].visibility) {
            return data[index]
        }
        index += direction;
    }
    return
}

function generateForwardBack(data, url) {
    let current = findArticle(data, url);
    let newer = findFirstInDirection(data, url, -1);
    let older = findFirstInDirection(data, url, 1);
    // TODO: fix assumption there will always be a 0.
    if (!current.visibility) {
        older = newer = {
            "url": "",
            "title": "Back to words"
        }
    }

    if (data.length == 1) {
        return "";
    }
    return (
        <div className="bottom-nav">
            <div className="half left-half">
                {older && <Link to={`/words/${older.url}`}>&#171; {older.title}</Link>}
            </div>
            <div className="half right-half">
                {newer && <Link to={`/words/${newer.url}`}>{newer.title} &#187;</Link>}
            </div>
        </div>
    );
}


export {
    generateTags,
    generateArticle,
    findArticle,
    generateForwardBack
};