import React from 'react';
import Breadcrumb from './Breadcrumb.js'
import Footer from './Footer.js'

function DefaultLayout(props) {
  return (
    <React.Fragment>
        <Breadcrumb stack={props.stack} />
        <h1>{props.title}</h1>
        {props.content}
        <Footer />
    </React.Fragment>
  );
}

export default DefaultLayout;
