import React from 'react';
import Breadcrumb from './Breadcrumb.js'
import Footer from './Footer.js'

function CentralLayout(props) {
    return (
        <React.Fragment>
            <Breadcrumb stack={props.stack} />
            <section className="content central">
                {props.header}
                {props.content}
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default CentralLayout;