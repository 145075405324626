import ContentFrontPage from './ContentFrontPage.js';
import TopNavigation from 'src/components/TopNavigation';
import {wordNav} from './words_constants.js'

const allWords = require('src/content/words/index.json');

function WordsContentFrontPage() {
    let content = allWords.filter(w => w.visibility);
    let wordsHeader = (
        <header>
        <TopNavigation links={wordNav}/>
        </header>
    );
    return <ContentFrontPage name="words" content={content} header={wordsHeader} />;
}

export default WordsContentFrontPage;
