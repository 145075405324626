import React from 'react';
import ReactMarkdown from 'react-markdown';
import ContentFrontPage from './ContentFrontPage'

function TagFilteredPage(props) {
    let noContent = undefined;
    if (!props.content || props.content.length == 0) {
        noContent = (
            <React.Fragment>
                <p>No results found.</p>
            </React.Fragment>
        );
    }

    const alphaNumeric = /[^A-Za-z0-9-]/g
    let tag = props.tag;
    if (tag != undefined) {
        tag = tag.replace(alphaNumeric, "");
    }

    const tagHeader = (
        <header className="tag-header">
        <p>Tag: <i>{tag}</i></p>
        <hr/>
        </header>
    );

    let stack = [{"url": "/", "name": "home"}];
    if (props.pageType != undefined) {
        stack.push({"url": "/" + props.pageType, "name": props.pageType});
        stack.push({"url": "/" + props.pageType + "/tag", "name": "tag"});
        stack.push({"url": null, "name": tag});
    }
    if (props.stack != undefined) {
        stack = props.stack;
    }

    return <ContentFrontPage stack={stack} content={props.content} header={tagHeader} contentOverride={noContent}/>;
}

export default TagFilteredPage;

