import { BrowserRouter, Link, Routes, Route, Switch } from "react-router-dom";
import React from 'react';

import HomePage from "./pages/HomePage";
import WordsContentFrontPage from "./pages/WordsContentFrontPage";
import WordsTagFilteredPage from "./pages/WordsTagFilteredPage";
import WordsContentIndexPage from "./pages/WordsContentIndexPage";
import WordsTagsPage from "./pages/WordsTagsPage";
import WordsSubpage from "./pages/WordsSubpage";
import ContactPage from "./pages/ContactPage";
import InvalidPage from "./pages/InvalidPage";
import ImagesContentFrontPage from "./pages/ImagesContentFrontPage";

import './static/css/default.css'

// Rendered by index.js.
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="words" element={<WordsContentFrontPage />} />
          <Route path="words/tag" element={<WordsTagsPage />} />
          <Route path="words/tags" element={<WordsTagsPage />} />
          <Route path="words/tag/:tag" element={<WordsTagFilteredPage />} />
          <Route path="words/tags/:tag" element={<WordsTagFilteredPage />} />
          <Route path="words/index" element={<WordsContentIndexPage />} />
          <Route path="words/:page" element={ <WordsSubpage /> } />
          <Route path="contact" element={<ContactPage />} />
          <Route path="images" element={<ImagesContentFrontPage />} />
          <Route path="*" element={<InvalidPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

//<!--           -->
export default App;
