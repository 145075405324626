import React from 'react';
import ReactMarkdown from 'react-markdown';
import CentralLayout from 'src/components/CentralLayout'
import { BrowserRouter, Link, Routes, Route, Switch, useParams } from "react-router-dom";

function TagsPage(props) {
    const alphaNumeric = /[^A-Za-z0-9-]/g;
    // Produces Map of tag => count
    let tagCount = props.tags.map((t, i) => {
        if (t == undefined) {
            return;
        }
        let tag = t.replace(alphaNumeric, "");
        return tag;
    }).filter(item => item != undefined && item != ""
    ).reduce((a, b) => (a.get(b) ? a.set(b, a.get(b) + 1) : a.set(b, 1) , a), new Map()
    );
    // Sort
    tagCount = [...tagCount.entries()].sort((a, b) => b[1] - a[1])
    let content = (
        <React.Fragment>
            <section className="tags-section">
            <ul>
            {tagCount.map((t, i) => {
                return (
                    <li><Link to={`/${props.pageType}/tag/${t[0]}`}>
                        {t[0]} ({t[1]})
                    </Link></li>
                );
            })}
            </ul>
            </section>
        </React.Fragment>
    );

    let stack = [{"url": "/", "name": "home"}];
    if (props.pageType != undefined) {
        stack.push({"url": "/" + props.pageType, "name": props.pageType});
        stack.push({"url": null, "name": "tags"});
    }
    if (props.stack != undefined) {
        stack = props.stack;
    }

    return (<CentralLayout
        stack={stack}
        content={content}
        header={props.header}
    />);
}

export default TagsPage;

