import React from 'react';
import DefaultLayout from './../components/DefaultLayout';
import { BrowserRouter, Link, Routes, Route, Switch } from "react-router-dom";

function HomePage() {
    const content = (
        <React.Fragment>
            <p>I&apos;m a person on the internet.</p>
            <p>I sometimes write <Link to="/words">words</Link>, make things, and <Link to="/images">draw</Link>.</p>
            <p>Thank you for refuting entropy in this moment in time.</p>
        </React.Fragment>
    );
    return (
        <React.Fragment>
            <DefaultLayout
                stack={[{"url": "/", "name": "home"}]}
                title={"hi"}
                content={content}
            />
        </React.Fragment>
    );
}

export default HomePage;
