import React, { useState } from 'react';

// Aim is to return the last edit time of the file, e.g. in the format
// Fri Jul 14 23:00:35 2023.
// Unfortunately React doesn't support fs stuff right now. TODO.
//function lastEditTime(filePath, hook) {
//    fs.stat(filePath, (err, stats) => {
//        let mtime = stats.mtime;
//        hook(mtime);
//    });
//}

function Footer(props) {
    // Set to "now" as a placeholder.
    const [lastModified, setLastModified] = useState(new Date().toGMTString());
    const darkStyles = new CSSStyleSheet();
    darkStyles.insertRule("* { background: #000; color: #fff; }");
    darkStyles.insertRule("hr { height: 1px; background-color: #fff; border: none; }");
    const handleToggle = () => {
        if (document.adoptedStyleSheets.length == 0) {
            document.adoptedStyleSheets = [...document.adoptedStyleSheets, darkStyles];
        } else {
            document.adoptedStyleSheets.splice(document.adoptedStyleSheets.indexOf(darkStyles));
        }
    }
    // document.lastModified
    // lastEditTime(props.filePath, setLastModified);
    return (
        <React.Fragment>
            <section className="footer">
                <hr />
                <p>Last modified: {lastModified}</p>
                <p><i>Click to <a onClick={handleToggle} href="#">toggle night mode</a>.</i></p>
            </section>
        </React.Fragment>
    );
}

export default Footer;
